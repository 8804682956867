import { css } from '@emotion/react'
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';

import { TitleIcon } from '@findep/mf-landings-core'
import { Typography, Grid, Button, Box, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import ManagerCard from '../forms/Beneficiarios/ManagerCard'

import HowToRegIcon from '@material-ui/icons/HowToReg';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import CarouselImg from '../img/CarouselImg.compilable'

import { InsuranceService } from '../../services/LandingV4/insurance'
import { navigate } from '../../helpers/queryNavigate';

const root = css`
    padding: 1rem;
`
const text = css`
    padding: 1rem;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.87);
`

const btnContainer = css`
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
`

const rootBtn = css`
    border-radius: 20px;
`

const imgContent = css`
    display:flex;
    align-items: center;
    justify-content: center;
`
const stleAlert = css`
float: right;
position: -webkit-sticky;
position: fixed;
bottom: 15px;
right: 0;
z-index:100;
`

const useStyles = (theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  });


class Beneficiarios extends Component {

    constructor(props){
        super(props)
        this.state = {
            generarError: true,
            beneficiaries: [],
            textAlert:'',
            flujo: '',
            openBackDrop: false,
            errorService: false,
        }
    }
 
    componentDidMount(){
        let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        this.setState({ flujo })
    }

    handleError = (e) => {
        this.setState({generarError: e.value})
    }

    handleData = (e) => {
        this.setState({beneficiaries: e})
    }

    handleNextStep = async () => {
        this.setState({
            openBackDrop:true
        })
        const beneficiaries = new InsuranceService(this.state.flujo.creditApplicationId)
        beneficiaries.setBeneficiaries(this.state.beneficiaries)
        try {
            const { status } = await beneficiaries.send()
            if (status === 200 || status === 204) {
                this.setState({textAlert: ''})
                sessionStorage.setItem('flujo',JSON.stringify({...this.state.flujo, tubo:{...this.state.flujo.tubo,ConfigureLifeInsurance: 'Completed'}}))
                const navigateUrl = this.props.pageContext.next.path;
                navigate(navigateUrl);                
            }
        } catch (error) {
            this.setState({
                openBackDrop:false,
                errorService: true,
            })
            //El envio al server fallo
            if(error.status === 400){
                this.setState({textAlert: 'La suma de porcentajes de los beneficiarios debe ser 100%'})
            }
        }
    }

    render() {
        const { classes, companyName } = this.props;
        return (
            <Grid container>
                <Grid item xs={12} md={6} css={root}>
                {
                    this.state.textAlert !== '' && <Alert css={stleAlert} variant="filled" severity="error">{this.state.textAlert}</Alert>
                }
                    <Grid container justify="center">
                    <TitleIcon title="Beneficiario de seguro de vida" icon={HowToRegIcon} textColor={true} />
                    </Grid>
                    <Typography css={text}>
                            Por favor, ingresa 2 beneficiarios (amigos, familiares, gente de confianza, compañeros de trabajo, etc.)
                    </Typography>
                    <ManagerCard onChange={this.handleData} onError={this.handleError}/>
                    <Box css={btnContainer}>
                        <Button
                        id="seguro-vida-nextStep-button"
                          variant="contained"
                          color="primary"
                          startIcon={<TrendingFlatIcon />}
                          css={rootBtn}
                          disabled={this.state.generarError}
                          onClick={this.handleNextStep}
                        >
                          Continuar
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} css={imgContent}>
                        <CarouselImg filename="4.svg" company={companyName} width="80%" />
                </Grid>
                <Backdrop open={this.state.openBackDrop} className={classes.backdrop}>
                  <CircularProgress color="primary" />
                </Backdrop>
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        Lo sentimos, por el momento el servicio no se encuentra disponible
                    </Alert>
                </Snackbar>
            </Grid>
        );
    }
}

export default withStyles(useStyles)(Beneficiarios);
